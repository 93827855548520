import Axios from "axios";
import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosInterceptorManager,
  CreateAxiosDefaults,
} from "axios";
import type { ServerResponseData } from "./request";

interface RequestConfig extends AxiosRequestConfig {
  headers?: any;
}

class Reuqest {
  axiosInstance: AxiosInstance;
  /**
   *
   * @param config Axios 初始化配置
   */
  constructor(config: CreateAxiosDefaults) {
    this.axiosInstance = Axios.create(config);

    this.initInterceptors();
  }

  /**
   * @description 初始化 axios 拦截器
   */
  private initInterceptors() {
    this.axiosInstance.interceptors.response.use(async (res) => {
      return Promise.resolve(res.data);
    });
    this.axiosInstance.interceptors.request.use(
      (config: RequestConfig) => {
        if (config && config.headers["Content-Type"]) {
          config.headers["Content-Type"] = "application/json;charset=UTF-8;";
        }
        if (config.method === "get") {
          config.data = { unused: 0 }; // 这个是关键点，加入这行就可以了  解决get  请求添加不上content_type
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  getAxiosInstace() {
    return this.axiosInstance;
  }
}
export default new Reuqest({
  baseURL: "/api",
}).getAxiosInstace()<any, ServerResponseData>;
